body {
  font-family: "Poppins", sans-serif;
  background: #090a1a;
  font-size:14px;
  color: #9ca0d2;
  position: relative;
} 
a, button {
  color: white;
  text-decoration: none;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
}
a:hover, button:hover {
  color: white;
}
button {
  border:0;
}
header {
  position: relative;
  z-index: 1;
  width: 100%;
  min-height: 88px;
}
.sticky {
  padding: 16px 0;
}
.sticky-fixed {
  position: fixed;
  top: 0;
  background: #121117;
  width: 100%;
}
.navbar-toggler {
  font-size: 34px;
  color: white;
  background-image: linear-gradient(150deg, #3f5c97 0%, #0ca2ab 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.main-menu ul.navbar-nav {
  margin: 0px auto;
}
.main-menu ul > li > a, .nav-link:focus, .main-menu ul > li > button {
  color: white;
  font-weight: 600;
  font-size: 1rem;
  padding: 0px 1rem !important;
  background: none;
}
.main-menu ul > li > a.active, .main-menu ul > li > a:hover, .main-menu ul > li > button.active, .main-menu ul > li > button:hover {
  color: #1b8dec !important;
}

.button-default {
  background-image: linear-gradient(150deg, #550388 0%, #0347f7 78%);
  padding: 18px 2.4rem;
  border-radius: 36px;
  font-size: 16px;
  font-weight: 600;
  -webkit-transition: all .4s ease-out 0s;
  transition: all .4s ease-out 0s;
  background-size: 100% auto;
  align-items: center !important;
}
.button-default:hover {
  background-size: 200% auto;
}
.button-default i, .button-lined i {
  padding-right: 6px;
}
.button-lined {
  border: 2px solid #ffffff;
  display: inline-block;
  bottom: 0;
  border-radius: 36px;
  padding: 16px 2.6rem;
  margin: 0px auto;
  color: #ffffff;
  font-weight: 600;
}

.home {
  position: relative;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#090919), to(rgba(9, 10, 26, 0.8))), url(assets/home-bg.jpg);
    background-image: linear-gradient(0deg, #090919, rgba(9, 10, 26, 0.8)), url(assets/home-bg.jpg);
    background-position: 0 0, 0 0;
    background-size: auto, cover;
    background-repeat: repeat, no-repeat;
    background-attachment: scroll, fixed;
    margin-top: -84px;
}

.welcome-screen {
  text-align: center;
  color: white;
  padding: 14rem 0 6rem 0;
}
.welcome-screen h1 {
  font-size: 4.5rem;
  font-weight: 900;
  margin-bottom: 2rem;
  line-height: 1.2;
}
.welcome-screen p {
  color: #9f99c1;
  font-size: 18px;
}
.welcome-screen em {
  font-style: normal;
  color: #3691ff;
  letter-spacing: 6px;
}
.modal-content-lightbox {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  pointer-events: auto;
  /* background-clip: padding-box; */
  background: none;
}

.lightbox-close {
  position: absolute;
  top: 40px;
  right: 40px;
  background: white;
  width: 42px;
  height: 42px;
  border-radius: 50%;
}
/* sidebar */
.sidebar {
  background: #0f111c;
  width: 80% !important;
  max-width: 340px;
  padding: 0;
}
.sidebar h5 {
  color: white;
}
.sidebar .button-close {
  background-image: linear-gradient(150deg, #3f5c97 0%, #0ca2ab 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 26px;
}
.sidebar .offcanvas-body {
  padding-top: 0;
  padding-bottom: 0;
}
.sidebar-menu {
  margin: 0;
  padding: 0;
}
.sidebar-menu ul {
  display: flex;
  flex-direction: column !important;
  width: 100%;
}
.sidebar-menu ul li a {
  color: white;
  display: block;
  line-height: 52px;
  border-bottom: 1px solid #ffffff1a;
  padding: 0px !important;
  transition: all .4s ease-out 0s;
  font-weight: 600;
}

.sidebar-menu ul li a.active {
  color: #1b8dec !important;
}
/* sidebar */
/* advanced content */

.advanced-content-2 {
  margin: 0 30px;
}
.advanced-content {
  justify-content: flex-start !important;
  display: flex !important;
}
.sub-title {
  font-weight: 600;
  color: #0047ff;
  letter-spacing: 3px;
  font-size: 16px;
  position: relative;
}

.sub-title:after {
  position: absolute;
  content: '';
  height: 3px;
  width: 45px;
  background-color: #0047ff;
  top: 50%;
  margin-left: 20px;
}
.feature-column h2 {
  color: white;
  font-size: 3rem;
  font-weight: 700;
}
.advanced-content .icon-box {
  background: #0047ff;
  min-width: 100px;
  height: 100px;
  border-radius: 28px;
  margin-right: 1.8rem;
  text-align: center;
  line-height: 100px;
  font-size: 30px;
  color: white;
}
.advanced-content .content-box h3 {
  color: white;
  font-weight: 700;
}
.feature-column p {
  font-size: 16px;
  line-height: 1.8;
}
.advanced-content-2 .icon-box i {
  background-image: linear-gradient(150deg, #57048a 0%, #0047ff 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 40px;
}

.advanced-content-2 .icon-box {
  margin-bottom: 2rem;
}
.advanced-content-2 h3 {
  color: white;
  font-weight: 600;
  font-size: 1.5rem;
}

.advanced-content-2 p {
  line-height: 1.8;
  font-size: 16px;
}
.react-multi-carousel-list {
  padding-bottom: 30px;
}
.react-multi-carousel-dot button {
  background: transparent;
  border-color: #1571d7;
}
.react-multi-carousel-dot--active button {
  background: #1571d7 !important;
}
/* advanced content */

/* faqs */

.accordion-faqs .accordion-button {
  background: #16182d;
  color: #9ca0d2;
  border: 0 !important;
  font-size: 15px !important;
  padding: 22px 24px;
}

.accordion-faqs 
.accordion-item {
  background: transparent;
  border: 0 !important;
  color: white;
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  border: 0 !important;
  box-shadow: none !important;
}
.accordion-faqs .accordion-button:after {
filter: brightness(4)
}
/* faqs */

/* card */
.card {
  background: #16182d;
  padding: 45px;
  border-radius: 2rem;
  min-height: 320px;
  margin-bottom: 2rem;
      transition: all .4s ease-out 0s;
}

.card i {
  background-image: linear-gradient(150deg, #57048A 0%, #0047ff 78%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 44px;
  line-height: 44px;
}
.help-card {
  text-align: center;
}
.card h4 {
  margin-top: 1rem;
  margin-bottom: 20px;
  color: white;
}

.card p {
  color: #9ca0d2;
  line-height: 1.6;
  font-size: 16px;
}
.card:not(.no-hover):hover {
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.card-style-02 {
  background: #16182d;
  padding: 2.4rem;
  border-radius: 36px;
}

.card-style-02 h4 {
  color: white;
  font-weight: 700;
  margin-bottom: 24px;
}

.card-style-02 p {
  font-size: 16px;
  line-height: 1.8;
}
/* card */

/* bridging */
.card-style-02 label {
  font-size: 13px;
}
.progress {
  height: 24px;
  background: #1c1f40;
}
.progress-bar {
  border-radius: 36px;
}
.c-row {
  margin-bottom: 20px;
}

.c-row:last-child {
  margin: 0;
}
/* bridging */
/* table */
.compare-table table {
  color: #7a839f !important;
  text-align: center;
  border-bottom-left-radius: 36px;
  overflow: hidden;
  border-bottom-right-radius: 36px;
}
.compare-table tr {
  background: #16182d;
  border-color: #16182d;
}

.compare-table tr:nth-child(even) {
  background: #101126 !important;
}

.compare-table tr td, .compare-table tr th {
  padding: 22px 12px;
  background: transparent !important;
  border: 0;
  vertical-align: middle;
  font-weight: 400;
  font-size: 15px;
  color: #a4adc9 !important;
  min-width: 185px;
}

.compare-table .thead-dark tr {
  background: #1356de !important;
  color: white;
  font-weight: 700 !important;
}

.compare-table .thead-dark tr th {
  border: 0 !important;
  box-shadow: none !important;
  padding: 1.3rem 12px;
  background: transparent;
  color: white !important;
  font-weight: 600;
}

.compare-table .table>tbody {
  border: 0;
}

.compare-table .thead-dark tr th:first-child {
  border-top-left-radius: 16px;
}

.compare-table .thead-dark tr th:last-child {
  border-top-right-radius: 16px;
}

.compare-table tr td:nth-child(even) {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #161515;
}
.yes {
  color: #2ea790;
}
.no {
  color: #a44049;
}
tbody i {
  font-size: 24px;
}
/* table */

.container.bg-1 {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(9, 10, 26, 0.9)), to(rgba(9, 10, 26, 0.5))), url(assets/home-bg.jpg);
  background-image: linear-gradient(0deg, rgba(9, 10, 26, 0.9), rgba(9, 10, 26, 0.5)), url(assets/home-bg.jpg);
  padding: 3rem 2rem;
  border-radius: 2rem;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.banner-content {
  color: white;
}
.banner-content h2 {
  color: white;
  font-weight: 700;
  font-size: 2.5rem;
}
.banner-content p {
  line-height: 1.5;
  font-size: 16px;
  margin: 2rem 0;
  max-width: 480px;
}
.social-icons > a {
  margin: 5px;
  width: 45px;
  height: 45px;
  font-size: 20px;
  background: #16182d;
  color: white !important;
  border-radius: 14px;
  overflow: hidden;
  transition: all 0.3s ease 0s;
  text-align: center;
  line-height: 45px;
  align-items: center !important;
  display: flex;
  justify-content: center;
}
.social-icons > a:hover {
  background: #0347f7;
}

.terms-modal .modal-content {
  background: #141417;
}
.social-icons a svg {
  max-width: 100%;
  width: 100% !important;
  fill: white;
}
.terms-modal .modal-header {
  color: white;
  font-weight: 600;
  border-bottom: 1px solid #302c2c;
}
.terms-modal .modal-footer {
  border-top: 1px solid #302c2c;
}

.terms-modal .btn-secondary {
  background: #224fd8;
  border: 0;
  font-size: 16px;
  padding: 10px 24px;
}
.button-close {
  background: transparent;
  border: 0;
  color: white;
  font-size: 20px;
}
.copyright {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 30px;
}
@keyframes animation-01 {
  0% {
      transform: translateY(0);
  }

  25% {
      transform: translateY(-20px);
  }

  50% {
      transform: translateY(0);
  }

  75% {
      transform: translateY(20px);
  }

  100% {
      transform: translateY(0);
  }
}

.animation-01 img {
  animation: animation-01 6s infinite linear alternate;
}
@media (min-width: 1200px) {
.container {
    max-width: 1140px;
}
.react-multi-carousel-list {
  margin: 0px -30px;
}
}
@media (min-width: 681px) and  (max-width: 1024px) {
.welcome-screen {
  padding: 9rem 0 4rem 0;
}
.button-lined {
  margin-left: 12px;
}
}
@media (max-width: 680px) {
  body {
    font-size: 15px;
  }
  .sticky {
    padding: 16px 20px;
} 
.welcome-screen {
  padding: 8rem 0 4rem 0;
}
  .feature-column p {
    font-size: 15px;
}
  .welcome-screen h1 {
    font-size: 3rem;
  }
  .feature-column h2 {
    font-size: 2rem;
}
.compare-table tr td, .compare-table tr th {
  font-size: 13px;
}

.advanced-content .content-box h3 {
  font-size: 1.2rem;
}
.bridge-section .card-style-02 .col-3 {
  width: 36% !important;
}

.bridge-section .card-style-02 .col-9 {
  width: 64% !important;
}
.button-default, .button-lined {
  padding: 14px 1.6rem;
  font-size: 15px;
}
.button-lined {
  padding: 12px 1.6rem;
  margin-left: 12px;
}
  }
  @media (max-width: 400px) {
    .button-default, .button-lined {
      padding: 12px 16px;
  }
    .welcome-screen h1 {
      font-size: 38px;
  }
  .bridge-section .card-style-02 .col-3 {
    width: 100% !important;
  }
  
  .bridge-section .card-style-02 .col-9 {
    width: 100% !important;
  }
  .welcome-screen p {
    font-size: 16px;
    margin-bottom: 2rem !important;
}
}
@media (max-width: 340px) {
  .button-lined {
    margin-left: 0;
    margin-top: 10px;
}
.button-default, .button-lined {
  padding: 12px 22px;
}
}